.top {
    width: 100%;
    height: 50px;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    font-family: 'Josefin Sans', sans-serif;
    z-index: 3;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('https://media.istockphoto.com/photos/blurred-forest-background-natural-bokeh-picture-id1296944202?b=1&k=20&m=1296944202&s=170667a&w=0&h=ewIWbW5FyIhYVPC6HuIxQz-3q-bTDGlwhXYLq9ASNlU=');
}

.topLeft,
.topRight {
    flex: 3;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.topIcon {
    font-size: 20px;
    margin-right: 10px;
    color: black;
    cursor: pointer;
}


.topImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
}

.topCenter {
    flex: 6;
    height: 100%;
    align-content: center;
}

.topList {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    height: 50px;
    justify-content: center;
    align-items: center;
    list-style: none;
}

.topListItem {
    margin-right: 20px;
    font-size: 18px;
    font-weight: 300;
    cursor: pointer;
}

.topSearchIcon {
    font-size: 20px;
    margin-left: 15px;
    color: black;
    cursor: pointer;
}