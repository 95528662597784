.header {
    margin-top: 60px;
}

.headerTitles {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Lora', serif;
    color: black;

}

.headerTitlesAb {
    position: absolute;
    top: 19%;
    font-size: 30px;
    z-index: 2;
}

.headerTitlesBe {
    position: absolute;
    top: 32%;
    font-size: 30px;
    z-index: 2;
}

.headerTitlesLg {
    position: absolute;
    top: 21%;
    font-size: 100px;
    z-index: 2;
}

.headerImg {
    width: 100%;
    height: 450px;
    margin-top: -60px;
    object-fit: cover;
}

.headerImg:hover{
    -webkit-filter: blur(4px);
            filter: blur(4px);
}
.posts{
    flex: 9 1;
    font-family: 'Varela', sans-serif;
}
.sidebar {
    flex: 3 1;
    margin: 15px;
    padding-bottom: 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('https://media.istockphoto.com/photos/blurred-forest-background-natural-bokeh-picture-id1296944202?b=1&k=20&m=1296944202&s=170667a&w=0&h=ewIWbW5FyIhYVPC6HuIxQz-3q-bTDGlwhXYLq9ASNlU=');
}

.me-image {
    margin-top: 15px;
    height: 50%;
    width: 95%;
}

.sidebarItem {
    padding-top: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebarTitle {
    margin: 10px;
    padding: 5px;
    width: 80%;
    border-top: 1px solid #a7a4a4;
    border-bottom: 1px solid #a7a4a4;
}

.sidebarSocial {
    margin-top: 15px;
    width: 250px;
    display: flex;
    align-items: center;
}

.sidebarIcon {
    font-size: 16px;
    margin-left: 10px;
}
.home{
    display: flex;
}
.top {
    width: 100%;
    height: 50px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    font-family: 'Josefin Sans', sans-serif;
    z-index: 3;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('https://media.istockphoto.com/photos/blurred-forest-background-natural-bokeh-picture-id1296944202?b=1&k=20&m=1296944202&s=170667a&w=0&h=ewIWbW5FyIhYVPC6HuIxQz-3q-bTDGlwhXYLq9ASNlU=');
}

.topLeft,
.topRight {
    flex: 3 1;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.topIcon {
    font-size: 20px;
    margin-right: 10px;
    color: black;
    cursor: pointer;
}


.topImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
}

.topCenter {
    flex: 6 1;
    height: 100%;
    align-content: center;
}

.topList {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    height: 50px;
    justify-content: center;
    align-items: center;
    list-style: none;
}

.topListItem {
    margin-right: 20px;
    font-size: 18px;
    font-weight: 300;
    cursor: pointer;
}

.topSearchIcon {
    font-size: 20px;
    margin-left: 15px;
    color: black;
    cursor: pointer;
}
