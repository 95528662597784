.sidebar {
    flex: 3;
    margin: 15px;
    padding-bottom: 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('https://media.istockphoto.com/photos/blurred-forest-background-natural-bokeh-picture-id1296944202?b=1&k=20&m=1296944202&s=170667a&w=0&h=ewIWbW5FyIhYVPC6HuIxQz-3q-bTDGlwhXYLq9ASNlU=');
}

.me-image {
    margin-top: 15px;
    height: 50%;
    width: 95%;
}

.sidebarItem {
    padding-top: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebarTitle {
    margin: 10px;
    padding: 5px;
    width: 80%;
    border-top: 1px solid #a7a4a4;
    border-bottom: 1px solid #a7a4a4;
}

.sidebarSocial {
    margin-top: 15px;
    width: 250px;
    display: flex;
    align-items: center;
}

.sidebarIcon {
    font-size: 16px;
    margin-left: 10px;
}