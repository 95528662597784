.header {
    margin-top: 60px;
}

.headerTitles {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Lora', serif;
    color: black;

}

.headerTitlesAb {
    position: absolute;
    top: 19%;
    font-size: 30px;
    z-index: 2;
}

.headerTitlesBe {
    position: absolute;
    top: 32%;
    font-size: 30px;
    z-index: 2;
}

.headerTitlesLg {
    position: absolute;
    top: 21%;
    font-size: 100px;
    z-index: 2;
}

.headerImg {
    width: 100%;
    height: 450px;
    margin-top: -60px;
    object-fit: cover;
}

.headerImg:hover{
    filter: blur(4px);
}